<template>
<b-container>
  <b-overlay rounded="sm" :show="show">
    <b-row class="mb-2 justify-content-end">
      <b-col  md="8" lg="6">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Introduzca busqueda..." v-model="search">
          <div class="input-group-append">
            <button class="btn btn-success" @click="searchTour()" type="button"><feather-icon icon="SearchIcon" /></button>
            <button class="btn btn-primary" @click="searchReset()" type="button"><feather-icon icon="XIcon"/></button>
          </div>
        </div>
      </b-col>
    </b-row>  
    <b-row>
      <b-col lg="6" v-for="item, index in list" :key="index">  
        <a :href="'/tournament/' + item.id" class="text-black">
          <b-card no-body class="overflow-hidden visita">          
            <b-card-header class="bg-info">
              <b-card-title class="text-white">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path><line x1="4" y1="22" x2="4" y2="15"></line></svg>
                {{item.name }}{{ item.is_recurring == 1 ? ' - ' : '' }} <span class="is_recurring" v-if="item.is_recurring == 1">Recurrente</span>
              </b-card-title>           
            
            </b-card-header>
            
              <b-card-body>
                <div class="mt-2">       
                  <div v-for="player in item.players" class="mt-1">          
                    <p v-if="player.id == user.player.id" class="text-success">  
                      <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                      Ya estás inscrito/a a este torneo
                    </p>
                  </div>      
                  <span class="mr-2"><strong>Fecha :</strong>  {{formatDate(item.tournament_date)}} <strong>Hora:</strong> {{ item.tournament_hour ? formattedTime(item.tournament_hour) : ''}} </span>
                  <b-card-text class="mb-25"><strong>Nivel: </strong>{{ item.level }} <strong> - Número pistas:</strong> {{item.number_courts}}<br></b-card-text>
                 
                </div>
              </b-card-body>
    
          </b-card>
        </a>
      </b-col>
      <div class="text-center">
        <h5 v-if="list.length == 0" class="text-danger h4 text-center">¡NO HAY CANARIAS PRÓXIMAMENTE!</h5>
      </div>
    </b-row>
  </b-overlay>

</b-container>
</template>
<script>
import { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BRow, BCardHeader, BCard, BCardBody,BCardText, BButton, BCardTitle, BCol,BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex';
import moment from 'moment';
export default {
  props : ['type'],
  watch: { 
    type: function() { 
      this.show = true;
      this.search = '',
      this.list = [];
       
    }
  },   
  components: {VBModal, BForm, BFormInput, BFormTextarea, BContainer,BCardHeader, BCard, BRow, BCardBody,BCardText, BButton,BCardTitle, BCol,BOverlay},
  data() {
    return {
      show : false,     
      list : [],
      search : ''
    }
  },
  created () {
    this.show = true;
    this.$http.get("/api/getNextCanarias").then((response) => {
      this.list = response.data;      
      this.show = false;
    });   
  },
  methods: {
    searchTour () {
      this.show = true;
      this.$http.get("/api/getNextCanarias?search="+this.search).then((response) => {
        this.list = response.data;   
        this.show = false;
      }); 
    },
    searchReset() {
      this.search = '';
      this.searchTour();
    },
    

    formattedTime(hour) {
      // Usando Moment.js para dar formato a la fecha
      return moment(hour, 'HH:mm').format('HH:mm'); // Formato de 12 horas con AM/PM
    },
    formatDate(date){
      if (!date) return '';     
      return moment(date).format('DD-MM-YYYY');
    },
  },    computed: {
    ...mapState({
      user: state => state.auth.user,   
    }),
  }    
}
</script>
<style>
.notification-rounded {
  display: inline-block;
  width:25px;
  height: 25px;
  color: white;
  padding:5px;
  padding-top:3px;
  border-radius: 25px;
  text-align: center;
}
.green {
  background-color: green;
}

.grey {
  background-color: grey;
}
.is_recurring{
  background-color: rgb(226, 223, 67);
  color: black;
  padding: 0px 2px;
}
.text-black{
  color: black!important;
}
</style>